<template>
  <div>
    <el-breadcrumb style="margin: 0 0 20px 10px" separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>仓库管理</el-breadcrumb-item>
      <el-breadcrumb-item>出库列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <!-- <div class="tt">危废管理</div>
      <div class="xian"></div> -->
      <div class="list_box">
        <div class="tt1">出库列表</div>
        <div>
          <el-button type="add" icon="el-icon-circle-plus-outline" @click="add"
            >出库</el-button
          >
        </div>
      </div>
      <div class="int_box">
        <div class="int_box11">
          <span>产品名称：</span>
          <el-input
            class="int_width"
            v-model="name"
            placeholder="请输出产品名称"
            clearable
          ></el-input>
        </div>
        <div class="int_box11">
          <span>日期时间：</span>
          <el-date-picker
            style="margin-right: 50px"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="int_box11">
          <span>状态：</span>
          <el-radio-group v-model="state">
            <el-radio :label="''">全部</el-radio>
            <el-radio :label="1">未提交</el-radio>
            <el-radio :label="2">已提交</el-radio>
          </el-radio-group>
          <el-button
            style="margin-left: 50px"
            type="cha"
            icon="el-icon-search"
            @click="chaxun"
            >查询</el-button
          >
        </div>
      </div>
      <template>
        <el-table
          :header-cell-style="tableHeader"
          :data="userList"
          style="width: 100%"
          stripe
        >
          <el-table-column
            width="180px"
            align="center"
            prop="come_no"
            label="出库单号"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="product_name"
            label="产品名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="ware_name"
            label="库位名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="num"
            label="出库数量(吨)"
          ></el-table-column>
          <el-table-column align="center" label="出库类型">
            <template v-slot="scope">
              <div>{{ scope.row.operate_type === 1 ? '手动' : '扫码' }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="client_id"
            label="客户"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="admin_id"
            label="工作人员"
          ></el-table-column>
          <el-table-column
            width="166px"
            align="center"
            prop="create_at"
            label="创建时间"
          ></el-table-column>
          <el-table-column align="center" label="状态">
            <template v-slot="scope">
              <div>{{ scope.row.status === 1 ? '未提交' : '已提交' }}</div>
            </template>
          </el-table-column>
          <el-table-column width="200px" align="center" label="操作">
            <template v-slot="scope">
              <el-button
                v-if="scope.row.status === 1"
                size="mini"
                type="primary"
                icon="iconfont icon-bianxie"
                @click="edit(scope.row.id)"
                >编辑</el-button
              >
              <el-button
              v-if="scope.row.status === 1"
                size="mini"
                type="warning"
                icon="iconfont icon-shangbao"
                @click="tijiao(scope.row.id)"
                >提交</el-button
              >
              <el-button
                v-if="scope.row.status === 2"
                size="mini"
                type="success"
                icon="iconfont"
                @click="wuliu(scope.row.id)"
                >物流单</el-button
              >
              <el-button
                v-if="scope.row.status === 2"
                size="mini"
                type="success"
                icon="iconfont"
                @click="lujing(scope.row.id)"
                >路径信息</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="pageNumber"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <!-- 出库 -->
      <el-dialog
        :title="text"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible1"
        width="1100px"
        :before-close="handleClose1"
      >
        <div class="xian2"></div>
        <el-form
          ref="form1"
          :rules="rules1"
          :model="addform1"
          label-width="200px"
        >
          <el-form-item label="起始码号：">
            <el-input @input="int1" class="int" v-model="addform1.start"></el-input>
          </el-form-item>
          <el-form-item label="结束码号：">
            <el-input @input="int2" class="int" v-model="addform1.end"></el-input>
          </el-form-item>
          
          <el-form-item label="产品：" prop="product">
            <el-select @change="int3" v-model="addform1.product" placeholder="请选择">
              <el-option
                v-for="item in goodsList"
                :key="item.id"
                :label="item.product_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="出库重量(吨)：" prop="num">
            <el-input class="int" v-model="addform1.num"></el-input>
          </el-form-item>
          <el-form-item label="库位：" prop="ware">
            <el-select v-model="addform1.ware" placeholder="请选择">
              <el-option
                v-for="item in wareCheckList"
                :key="item.id"
                :label="item.ware_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <span style="margin-left:20px;color:#999">请先选择产品</span>
          </el-form-item>
          <el-form-item label="客户：" prop="client">
            <el-select v-model="addform1.client" placeholder="请选择">
              <el-option
                v-for="item in clientList"
                :key="item.id"
                :label="item.client_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注：" prop="remark">
            <el-input class="int" type="textarea" :autosize="{ minRows: 3}" v-model="addform1.remark"></el-input>
          </el-form-item>
        </el-form>
        <div class="btn_box">
          <el-button type="cha" class="add_btn111" @click="addRuku"
            >确定</el-button
          >
        </div>
      </el-dialog>
      <!-- 物流单  -->
      <el-dialog
        title="物流单"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible2"
        width="1100px"
        :before-close="handleClose2"
      >
        <div class="xian2"></div>
        <el-form
          ref="form2"
          :rules="rules2"
          :model="addform2"
          label-width="200px"
          :disabled="isflow"
        >
          <el-form-item label="车牌号：" prop="licence">
            <el-input class="int" v-model="addform2.licence"></el-input>
          </el-form-item>
          <el-form-item label="司机：" prop="driver">
            <el-input class="int" v-model="addform2.driver"></el-input>
          </el-form-item>
          <el-form-item label="司机手机号：" prop="mobile">
            <el-input class="int" v-model="addform2.mobile"></el-input>
          </el-form-item>
          <el-form-item label="物流公司：" prop="logistics_company">
            <el-input class="int" v-model="addform2.logistics_company"></el-input>
          </el-form-item>
          <el-form-item label="发运时间：" prop="time">
            <el-date-picker
                v-model="addform2.time"
                type="datetime"
                placeholder="选择日期时间">
                </el-date-picker>
          </el-form-item>
          <el-form-item label="备注：" prop="remark">
            <el-input class="int" type="textarea" :autosize="{ minRows: 3}" v-model="addform2.remark"></el-input>
          </el-form-item>
        </el-form>
        <div class="btn_box">
          
          <el-button v-if="isflow"  type="warning" class="add_btn111" @click="dayin"
            >打印</el-button
          >
          <el-button v-else type="cha" class="add_btn111" @click="addbaocun"
            >保存</el-button
          >
          
        </div>
      </el-dialog>
      <!-- 物流路径信息
       -->
       <el-dialog
        title="路径信息"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible3"
        width="1100px"
        :before-close="handleClose3"
      >
        <div class="xian2"></div>
        <template>
        <el-table
          :header-cell-style="tableHeader"
          :data="urlLogList"
          style="width: 100%"
          stripe
        >
          <el-table-column
            align="center"
            prop="address"
            label="位置"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="lat"
            label="纬度"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="lng"
            label="经度"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="create_at"
            label="时间"
          ></el-table-column>
          
        </el-table>
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange3"
          @current-change="handleCurrentChange3"
          :current-page="page3"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="pageNumber3"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total3"
        ></el-pagination>
      </div>
       </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
     var checkMobile = (rule, value, cb) => {
      const regMobile = /^1[3456789]\d{9}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的手机号'))
    }
    return {
      page: 1,
      pageNumber: 5,
      name: '',
      state: '',
      time: [],
      userList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333',
      },
      

      // 出库参数
      dialogVisible1: false,
      addform1: {
        product: '',
        num: '',
        start: '',
        end: '',
        ware: '',
        client: '',
        remark: ''
      },
      rules1: {
        num: [
          { required: true, message: '请输入出库重量', trigger: 'blur' },
        ],
        start: [{ required: true, message: '请输入二维码起始ID', trigger: 'blur' }],
        end: [{ required: true, message: '请输入二维码结束ID', trigger: 'blur' }],
        product: [{ required: true, message: '请选择商品', trigger: 'blur' }],
        ware: [
          { required: true, message: '请选择库位', trigger: 'blur' },
        ],
        client: [
          { required: true, message: '请选择客户', trigger: 'blur' },
        ]
      },
      text:'出库',
      //   产品列表
      goodsList: [],
    //   库位列表
    wareCheckList: [],
    // 客户列表 
    clientList: [],
    // 物流单参数
    dialogVisible2: false,
    addform2: {
      licence: '',
      driver: '',
      mobile: '',
      logistics_company: '',
      remark: '',
      time: ''
    },
    rules2: {
        licence: [
          { required: true, message: '请输入车牌号', trigger: 'blur' },
        ],
        driver: [{ required: true, message: '请输入司机', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入司机手机号', trigger: 'blur' },
        { validator: checkMobile, trigger: 'blur' }],
        logistics_company: [{ required: true, message: '请输入物流公司', trigger: 'blur' }]
      },
      ids: '',
      isflow: false,
      // 路径信息 
      urlLogList: [],
      total3: 0,
      page3: 1,
      pageNumber3: 10,
      dialogVisible3: false,
      lujingID: ''
    }
  },
  created() {
    this.user()
  },
  methods: {
    handleSizeChange(val) {
      this.pageNumber = val
      this.user()
    },
    handleCurrentChange(val) {
      this.page = val
      this.user()
    },
    handleSizeChange3(val) {
      this.pageNumber3 = val
      this.urlLog()
    },
    handleCurrentChange3(val) {
      this.page3 = val
      this.urlLog()
    },
    
    handleClose1() {
      this.dialogVisible1 = false
      this.$refs.form1.resetFields()
      this.addform1 = {
        product: '',
        num: '',
        start: '',
        end: '',
        ware: '',
        client: '',
        remark: '',
        id: ''
      }
      
    },
    handleClose2() {
      this.dialogVisible2 = false
      this.$refs.form2.resetFields()
      this.addform2 = {
        licence: '',
        driver: '',
        mobile: '',
        logistics_company: '',
        remark: '',
        time: '',
        id: ''
      }
      this.isflow = false
    },
    handleClose3() {
      this.dialogVisible3 = false
      this.urlLogList = []
      this.page3 = 1
    },
    

    // 出库列表
    async user() {
      const { data: res } = await this.$http.get('api/appear/index', {
        params: {
          page: this.page,
          pageNumber: this.pageNumber,
          name: this.name,
          start: this.time ? this.time[0] : '',
          end: this.time ? this.time[1] : '',
          state: this.state,
        },
      })
      console.log(11, res)
      this.userList = res.data.rows
      this.total = res.data.total
    },
    // 查询
    chaxun() {
      this.page = 1
      this.user()
    },
    
    // 点击出库
    add() {
        this.text = '出库'
      this.dialogVisible1 = true
      this.goods()
      this.client()
    },
    
    // 产品列表
    async goods() {
      const { data: res } = await this.$http.get('api/goods/index', {
        params: {
          page: 1,
          size: 200,
        },
      })
      console.log(11, res)
      this.goodsList = res.data.rows
    },
    // 出入库根据产品id选择库位
    async wareCheck(id) {
      const { data: res } = await this.$http.post('api/warehouse/goodsList',{
          id:id
      })
      console.log(11, res)
      this.wareCheckList = res.data
    },
    // 客户列表
    async client() {
      const { data: res } = await this.$http.get('api/client/index', {
        params: {
          page: 1,
          pageNumber: 200,
        },
      })
      console.log(11, res)
      this.clientList = res.data.rows
    },
    // 二维码起始变化
    int1(ev) {
        console.log(ev)
        if(this.addform1.start && this.addform1.end && this.addform1.product) {
            this.put()
        }
    },
    // 二维码结束变化
    int2(ev) {
       if(this.addform1.start && this.addform1.end && this.addform1.product) {
            this.put()
        }
    },
    // 添加时根据产品和码段获取对应数量
    int3(ev) {
        this.wareCheck(ev)
        this.addform1.ware = ''
        if(this.addform1.start && this.addform1.end && this.addform1.product) {
            this.put()
        }
    },
    // 添加时根据产品和码段获取对应数量
    async put() {
         const { data: res } = await this.$http.post('api/appear/getMessage', 
       {
          product: this.addform1.product,
          start: this.addform1.start,
          end: this.addform1.end
        },
      )
      console.log(11, res)
       if (res.code != 200) return this.$message.error(res.msg)
      this.addform1.num = res.data
    },
    // 出库
    addRuku() {
      this.$refs.form1.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整的数据')

        const { data: res } = await this.$http.post(
          'api/appear/saves',
          this.addform1
        )
        if (res.code != 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.dialogVisible1 = false
        this.$refs.form1.resetFields()  
        this.page = 1
        this.user()
      })
    },
    // 点击编辑
    edit(id) {
        this.text = '编辑'
        this.goods()
        this.client()
        this.editMessage(id)
        this.dialogVisible1 = true
    },
    // 编辑获取信息
    async editMessage(id) {
         const { data: res } = await this.$http.post('api/appear/editMessage', 
       {
         id: id
        },
      )
      console.log(11, res)
       if (res.code != 200) return this.$message.error(res.msg)
       this.wareCheck(res.data.product_id)
        this.addform1= {
        product: res.data.product_id,
        num: res.data.num,
        start: res.data.qrcode_start,
        end: res.data.qrcode_end,
        ware: res.data.ware_id,
        client: res.data.client_id,
        remark: res.data.remark,
        id: id
      }
     
    },
    // 提交
    tijiao(id) {
        this.$confirm('此操作将提交该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
            const { data: res } = await this.$http.post(
          'api/appear/submit',
          {id: id}
        )
        if (res.code != 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.page = 1
        this.user()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    // 生成物流单
    wuliu(id) {
      this.ids = id
      this.addform2.id = id
      this.dialogVisible2 = true
      this.logistics()
      
    },
    // 物流单
    async logistics() {
         const { data: res } = await this.$http.post('api/appear/logistics', 
       {
         id: this.ids
        },
      )
      console.log(11, res)
       if (res.code != 200) return this.$message.error(res.msg)
      if(res.data) {
        this.addform2= {
        licence: res.data.licence,
        driver: res.data.driver_name,
        mobile: res.data.driver_mobile,
        logistics_company: res.data.logistics_company,
        remark: res.data.logistics_remark,
        time: res.data.logistics_at,
        link: res.data.link,
        id: this.ids
      }
      this.isflow = true
      }else {
        this.isflow = false
      }
        
     
    },
    // 保存
    addbaocun() {
      this.$refs.form2.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整的数据')

        const { data: res } = await this.$http.post(
          'api/appear/generate',
          this.addform2
        )
        if (res.code != 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
       
        this.$refs.form2.resetFields()
        this.logistics()
      })
    },
    // 打印
    dayin() {
       this.dialogVisible2 = false
      window.open(this.$URL_img + 'api/appear/export?id=' + this.ids + '&token=' + window.localStorage.getItem('jd_token'))
    },
    // 点击路径信息
    lujing(id) {
      // this.dialogVisible3 = true
      // this.lujingID = id
      // this.urlLog()
      this.$router.push('/map?id=' + id)
    },
    // 物流路径信息
    async urlLog() {
      const { data: res } = await this.$http.get('api/appear/urlLog', {
        params: {
          page: this.page3,
          pageNumber: this.pageNumber3,
          id: this.lujingID
        },
      })
      console.log(11, res)
      this.urlLogList = res.data.rows
      this.total3 = res.data.total
    },

  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 30px;
  box-sizing: border-box;
  margin-bottom: 20px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 60px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 18px;
      padding: 4px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box {
    padding: 25px 1px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .int_box11 {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  .int_width {
    width: 300px;
    margin-right: 40px;
  }
  .int_width1 {
    width: 300px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 10px;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .int {
    width: 400px;
  }

  .add_btn {
    margin-left: 800px;
  }
  .danger_btn {
    margin-left: 20px;
  }
  .btn_box {
    width: 100%;
    text-align: center;
    padding: 30px 0;
  }
  .add_btn111 {
    width: 140px;
  }
}
</style>
